import React from "react";
import { useRouter } from "next/router";
import DatePicker from "../../src/hooks/DatePicker";
import { useState } from "react";
import {
  formatDate,
  getFormatDatePicker,
  getNow,
  getTomorrow,
} from "../utils/momentUtils";
import { useForm } from "react-hook-form";
import Select from "react-select";

const CheckAvailability = () => {
  const router = useRouter();
  const { startDate, endDate, adults, children } = router.query;
  const [date, setDate] = useState(
    startDate ? getFormatDatePicker(startDate) : getNow()
  );
  const [date2, setDate2] = useState(
    startDate ? getFormatDatePicker(endDate) : getTomorrow()
  );
  const { register, handleSubmit, setValue } = useForm();

  const saveData = (form_data) => {
    const formStartDate = formatDate(date);
    const formEndDate = formatDate(date2);
    router.push(
      `/room-list/1?startDate=${formStartDate}&endDate=${formEndDate}&adults=${
        form_data?.adults || 1
      }&children=${form_data?.children || 0}`
    );
  };

  const getAdultOption = () => {
    if (adults) {
      const numAdults = parseInt(adults);
      if (numAdults > 0 && numAdults <= 5) {
        const label = numAdults === 1 ? "1 Adult" : `${numAdults} Adults`;
        return { value: numAdults.toString(), label };
      }
    }
    return null;
  };

  const getChildrenOption = () => {
    if (children) {
      const numChildren = parseInt(children);
      if (numChildren >= 0 && numChildren <= 5) {
        const label =
          numChildren === 0 || numChildren === 1
            ? `${numChildren} Children`
            : `${numChildren} Childrens`;
        return { value: numChildren.toString(), label };
      }
    }
    return null;
  };

  const handleSelectChangeAdults = (selectedOption) => {
    setValue("adults", selectedOption?.value);
  };

  const handleSelectChangeChildren = (selectedOption) => {
    setValue("children", selectedOption?.value);
  };

  return (
    <div className="check-availability">
      <div className="auto-container">
        <form className="form" onSubmit={handleSubmit(saveData)}>
          <div className="left-side">
            <ul>
              <li>
                <DatePicker
                  date={date}
                  onChange={setDate}
                  placeholder={`Arrival Date`}
                />
              </li>
              <li>
                <DatePicker
                  date={date2}
                  onChange={setDate2}
                  placeholder={`Departure Date`}
                />
              </li>
              <li>
                <Select
                  {...register("adults")}
                  placeholder="Adults"
                  onChange={handleSelectChangeAdults}
                  className="custom-select"
                  options={[
                    { value: "1", label: "1 Adult" },
                    { value: "2", label: "2 Adults" },
                    { value: "3", label: "3 Adults" },
                    { value: "4", label: "4 Adults" },
                    { value: "5", label: "5 Adults" },
                  ]}
                  instanceId={"select-checkavailability-adults"}
                  id="select-adults-home"
                  defaultValue={getAdultOption()}
                />
              </li>
              <li>
                <Select
                  {...register("children")}
                  placeholder="Children"
                  onChange={handleSelectChangeChildren}
                  className="custom-select"
                  options={[
                    { value: "0", label: "0 Children" },
                    { value: "1", label: "1 Children" },
                    { value: "2", label: "2 Childrens" },
                    { value: "3", label: "3 Childrens" },
                    { value: "4", label: "4 Childrens" },
                    { value: "5", label: "5 Childrens" },
                  ]}
                  instanceId={"select-checkavailability-childrens"}
                  id="select-children-home"
                  defaultValue={getChildrenOption()}
                />
              </li>
            </ul>
          </div>
          <div className="right-side">
            <button type="submit">Check Availability</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckAvailability;
