import moment from "moment";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const weekDaysLabel = {
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
  Sunday: "SUN",
};

export const numberToTime = (number) => {
  if (Number.isInteger(number)) {
    // If it's a whole number, format as HH:00
    return moment({ hour: number }).format("HH:00");
  } else {
    // If it's a decimal, separate the integer and decimal parts
    const integerPart = Math.floor(number);
    const decimalPart = (number - integerPart) * 60;
    // Format as HH:mm
    return moment({ hour: integerPart, minute: decimalPart }).format("HH:mm");
  }
}

export const timeToNumber = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);
  // Calculate the number
  return hours + minutes / 60;
};

export const updateTimeHourString = (date, time) => {
  const [newHours, newMinutes] = time.split(':');
  return  date.clone().set({
    hour: newHours,
    minute: newMinutes,
  });
}

export const getHourByTimeString = (timeString) => {
  const date = moment(`2000-01-01T${timeString}`);
  return date.format("hh:mma");
};

export const getNow = () => {
  const now = moment();
  return now.format("YYYY-MM-DDTHH:mm:ss");
};

export const getTomorrow = () => {
  const tomorrow = moment().add(1, "day");
  return tomorrow.format("YYYY-MM-DDTHH:mm:ss");
};

export const getNowF = () => {
  const now = moment();
  return now.format("YYYY-MM-DD").toString();
};

export const getTomorrowF = () => {
  const tomorrow = moment().add(1, "day");
  return tomorrow.format("YYYY-MM-DD").toString();
};

export const formatDate = (timeString) => {
  const date = moment(timeString);
  return date.format("YYYY-MM-DD").toString();
};

export const getFormatDatePicker = (timeString) => {
  const date = moment(timeString);
  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export const getDaysBetweenDates = (date1, date2) => {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);

  // Validation: Checking if the first date is after the second date
  if (momentDate1.isAfter(momentDate2)) {
    return -1;
  }

  // Calculating the difference in days
  const duration = moment.duration(momentDate2.diff(momentDate1));
  const days = duration.asDays();

  // Rounding off the number of days
  const roundedDays = Math.round(days);

  return roundedDays;
};

export const datesAreBeforeNow = (date1, date2) => {
  const now = moment().startOf("day"); // Get the current date at the start of the day

  const isBeforeCurrentDate1 = moment(date1, "YYYY-MM-DD").isBefore(now, "day");
  const isBeforeCurrentDate2 = moment(date2, "YYYY-MM-DD").isBefore(now, "day");
  return isBeforeCurrentDate1 || isBeforeCurrentDate2;
};

export const formatHour = (hour) => {
    const parsedTime = moment(hour, "HH:mm:ss.SSS");
    return parsedTime.format("h:mm A");
};

export const formatHourSimple = (hour) => {
  const parsedTime = moment(hour, "HH:mm:ss.SSS");
  return parsedTime.format("HH:mm");
};

export const getFirstDayWeekByWeekNumber = (year, weekNumber) => {
  return moment().isoWeekYear(year).isoWeek(weekNumber).startOf("isoWeek");
};

export const getMonthByWeekNumber = (year, weekNumber) => {
  const startDateOfWeek = getFirstDayWeekByWeekNumber(year, weekNumber);
  return startDateOfWeek.format('MMMM');
};

export const getWeekDays = (week) => {
  return weekDays.map((day, index) => {
    const date = week.clone().add(index, "days");
    return { day, date: date };
  });
};

